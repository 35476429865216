import React from 'react'
import Layout from '../components/base/layout'
import SEO from '../components/base/seo'

const style = {
    paddingTop: '100px',
    paddingBottom: '100px',
};

const TestPage = () => {

    return (
        <Layout>
            <SEO title="Advanced Example"/>
            <div className="container" style={style}>
            </div>
        </Layout>
    )
};

export default TestPage;
